#container {
  padding: 10px;
  text-align: center; }
  #container #question {
    color: #888;
    font-size: 1.5em;
    padding: 5px; }
  #container #answers, #container #buttons {
    font-size: 2em;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: center; }
    #container #answers input, #container #buttons input {
      display: none; }
      #container #answers input + label.answer, #container #buttons input + label.answer {
        cursor: pointer;
        margin: 2% 1%;
        border-radius: 10px;
        border: 6px solid transparent;
        color: #EEE;
        display: flex;
        align-content: stretch;
        align-items: stretch;
        justify-content: center;
        width: 100%;
        filter: grayscale(40%);
        opacity: .9; }
        @media (orientation: landscape) {
          #container #answers input + label.answer, #container #buttons input + label.answer {
            margin: 1% 1%;
            width: 42%;
            font-size: .9em; } }
        #container #answers input + label.answer div, #container #buttons input + label.answer div {
          align-content: center;
          border-radius: 6px;
          background: #0A70A8;
          padding: .3em 2%;
          margin: 3px;
          flex-grow: 1; }
        #container #answers input + label.answer.textual div, #container #buttons input + label.answer.textual div {
          font-size: .8em; }
      #container #answers input + label.onechar, #container #buttons input + label.onechar {
        font-size: 1.4em; }
      #container #answers input:checked + label.answer, #container #buttons input:checked + label.answer {
        border-color: #257D10;
        opacity: 1;
        filter: grayscale(0); }
        #container #answers input:checked + label.answer div, #container #buttons input:checked + label.answer div {
          background: #257D10; }
  #container #buttons {
    display: flex;
    flex-direction: column; }
    #container #buttons .button {
      cursor: pointer;
      margin: 3% 1%;
      padding: 1em;
      border-radius: 6px;
      text-decoration: none;
      color: #EEE;
      background: #0A70A8; }
  #container.multi {
    font-size: .9em; }
    #container.multi #answers input + label div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      width: 100%; }
      #container.multi #answers input + label div span {
        flex: 1; }
      #container.multi #answers input + label div::before {
        font-family: ircad_vote;
        content: '\f096';
        padding: 0 .2em;
        width: 1em;
        text-align: left; }
    #container.multi #answers input + label.onechar > div::before {
      position: absolute;
      left: 0.2em; }
    #container.multi #answers input:checked + label.answer div::before {
      content: '\e800'; }
    #container.multi #ok {
      display: block; }
